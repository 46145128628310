import {
  ClipboardCheckIcon,
  CreditCardIcon,
  LockClosedIcon,
  TicketIcon,
  UserIcon,
  VideoCameraIcon,
  XIcon,
} from '@heroicons/react/outline'
import _ from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'
import QueryString from 'qs'
import React, { useCallback } from 'react'

import { IProfile } from '../../stores/auth'
import styles from './Dropdown.module.scss'
import { NavTag } from './NavTag'
interface IDropdownProps {
  profile: IProfile | null
  isSigned?: boolean
  signInUrl: string
  ondropDownToggleHandler: () => void
}

const MobileDropdown = ({
  profile,
  isSigned,
  signInUrl,
  ondropDownToggleHandler,
}: IDropdownProps) => {
  const router = useRouter()
  const logout = useCallback(() => router.push('/oauth/logout'), [router])
  const state = QueryString.stringify({ state: location.pathname })
  const signInUrlWithState = `${signInUrl}&${state}`
  const goToSignIn = useCallback(() => router.push(signInUrlWithState), [
    router,
    signInUrlWithState,
  ])

  const HeaderNavList = [
    {
      title: '부트캠프',
      subTitle: '100% 전액지원 멋쟁이사자처럼 국비지원 부트캠프',
      tag: <NavTag name="국비지원" />,
      href: '/school',
    },
    {
      title: '6주완성',
      subTitle: '나만의 커리어 완성의 시작, 비전공자 대상 온라인 기초 교육',
      tag: <NavTag name="전액환급" />,
      href: '/onboarding',
    },
    {
      title: '이벤트',
      subTitle: '기업연계 프로젝트, 해커톤, 콘퍼런스 등 알짜배기 이벤트',
      href: '/event',
    },
    {
      title: '기업 문의',
      subTitle: '최신 IT 트렌드를 바탕으로 한 맞춤형 기업 교육',
      href: '/enterprise',
    },
  ]

  return isSigned ? (
    <div
      className={`fixed left-0 top-0 z-75 w-full cursor-default overflow-y-scroll bg-white ${styles.mobileWrap}`}>
      <button className="absolute right-5 top-4 z-50" onClick={ondropDownToggleHandler}>
        <XIcon className="h-auto w-6 text-gray-500" />
      </button>
      <section className="mt-14 px-5 py-4">
        <header className="flex flex-col ">
          <h3 className="text-lg font-bold">{profile?.name} 님</h3>
          <p className="text-sm text-gray-500">{profile?.email}</p>
          <ul className="mt-4 flex w-full max-w-[760px] flex-row space-x-2">
            {PopularMenuList.map(({ title, icon, href }) => (
              <Link key={`자주사용하는_${title}`} href={href} as={href}>
                <li className="box-border flex w-full flex-col items-center justify-center space-y-1.5 rounded-lg bg-neutral-50 py-4">
                  {icon}
                  <p className="text-sm">{title}</p>
                </li>
              </Link>
            ))}
          </ul>
        </header>
        <main className="space-y-3 pt-3">
          <ul>
            {HeaderNavList.map(({ title, tag, href, subTitle }) => (
              <li className="py-4 first:pt-3 last:pb-3" key={`${title}_${href}`}>
                <Link href={href} as={href}>
                  <div className="flex flex-col">
                    <p className="flex flex-row items-center space-x-1.5">
                      <span className="text-base">{title}</span>
                      {tag && <>{tag}</>}
                    </p>
                    <span className="mt-1.5 block break-words break-keep text-xs text-gray-500">
                      {subTitle}
                    </span>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
          <div>
            {myPageList.map(({ menuList }, idx) => (
              <ul key={`마이페이지_${idx}`}>
                {menuList.map(({ text, icon, href }) => (
                  <li className="py-4" key={`${text}_${href}`}>
                    <Link href={href}>
                      <div className="flex items-center">
                        {icon}
                        <span className="relative ml-4 inline-block text-base">{text}</span>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </main>
        <div className="mt-6 py-4">
          <button className="inline-block text-sm text-gray-500 underline" onClick={logout}>
            로그아웃
          </button>
        </div>
      </section>
    </div>
  ) : (
    <div className={`fixed left-0 top-0 w-full overflow-y-scroll bg-white ${styles.mobileWrap}`}>
      <button className="absolute right-5 top-4 z-50" onClick={ondropDownToggleHandler}>
        <XIcon className="h-auto w-6 text-gray-500" />
      </button>
      <section className="pt-14">
        <ul className="px-5">
          {HeaderNavList.map(({ title, tag, href, subTitle }) => (
            <li className="py-4 first:pt-3 last:pb-3" key={`${title}_${href}`}>
              <a href={href} className="flex items-start">
                <div className="flex flex-col">
                  <p className="flex flex-row items-center space-x-1.5">
                    <span className="font-medium">{title}</span>
                    {tag && <>{tag}</>}
                  </p>
                  <span className="mt-1.5 block break-words break-keep text-xs text-gray-500">
                    {subTitle}
                  </span>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </section>
      <div className="px-5 pb-12 pt-4">
        <div className="flex pt-6">
          <button className="rounded-full bg-yellow-450 px-4 py-2" onClick={goToSignIn}>
            <span className="text-sm font-medium text-white">로그인</span>
          </button>
        </div>
        <p className="mt-4 text-left text-sm">
          멋쟁이사자처럼에서 내 꿈을 실현시켜줄 <br /> 멋진 클래스들을 만나보세요!
        </p>
      </div>
    </div>
  )
}

export default MobileDropdown

const myPageList = [
  {
    menuList: [
      {
        text: '비밀번호 변경',
        href: '/my/change-password',
        icon: <LockClosedIcon className="h-auto w-5" />,
      },
      {
        text: '결제 내역 / 환불',
        href: '/my/payments',
        icon: <CreditCardIcon className="h-auto w-5" />,
      },
      {
        text: '내 쿠폰',
        href: '/my/coupons',
        icon: <TicketIcon className="h-auto w-5" />,
      },
    ],
  },
]

const PopularMenuList = [
  {
    title: '강의목록',
    icon: <VideoCameraIcon className="h-auto w-7 [&>path]:stroke-[1.5]" />,
    href: '/my/courses',
  },
  {
    title: '수강신청',
    icon: <ClipboardCheckIcon className="h-auto w-7 [&>path]:stroke-[1.5]" />,
    href: '/my/apply-status',
  },
  {
    title: '내정보',
    icon: <UserIcon className="h-auto w-7 [&>path]:stroke-[1.5]" />,
    href: '/my/profile',
  },
]
